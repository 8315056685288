import React, { useState } from "react";
import CommonSection from "../../components/Layout/CommonSection";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getDocs } from "../../redux/actions/actions";
import { useTranslation } from "react-i18next";
import Image from '../../assets/no-results-bg.2d2c6ee3.png'

export default function OpenDocs() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location1 = useLocation();

  const year = new Date().getFullYear();

  const [stateYear, setStateYear] = useState(year.toString());

  const [openIndex, setOpenIndex] = useState(null);

  const { location } = useSelector((state) => state.location);
  const { docs } = useSelector((state) => state.docs);

  useEffect(() => {
    dispatch(getDocs(location1.pathname === "/xisobotlar" ? "REP" :
                    location1.pathname === "/bitimlar" ? "AFFILIATIONS" :
                    location1.pathname === "/ragbatlantirish-miqdori" ? "COMPENSATION" :
                    location1.pathname === "/korparativ-boshqaruv" ? "EVALUATION" :
                    location1.pathname === "/ijro-organi" ? "PERFORMANCE" :
                    location1.pathname === "/nosohaviy-aktivlar" ? "NON_ASSETS" :
                    location1.pathname === "/moliyaviy-natijalar" ? "FINANCIALS" :
                    location1.pathname === "/biznes-reja" ? "BUSINESS_STATUS" :
                    location1.pathname === "/rivojlanish-strategiyasi" ? "STRATEGIES" : "DOC"
    ));
  }, [location1, dispatch]);

  const handleToggle = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const filteredData = docs?.filter(
    (item) => item?.year.toString() === stateYear
  );

  return (
    <section className="">
      <CommonSection
        title={
          location1.pathname === "/xisobotlar" ? (location === "uz" ? "Hisobotlar" : location === "ru" ? "Отчеты" : "Reports") :
          location1.pathname === "/biznes-reja" ? (location === "uz" ? "Biznes-reja ijrosi holati" : location === "ru" ? "Статус выполнения бизнес-плана" : "Status of business plan execution") :
          location1.pathname === "/rivojlanish-strategiyasi" ? (location === "uz" ? "Rivojlantirish strategiyalari" : location === "ru" ? "Стратегии развития" : "Development strategies") :
          location1.pathname === "/moliyaviy-natijalar" ? (location === "uz" ? "Buxgalteriya balansi va moliyaviy natijalar" : location === "ru" ? "Баланс и финансовые результаты" : "Balance sheet and financial results") :
          location1.pathname === "/nosohaviy-aktivlar" ? (location === "uz" ? "Korxonalar va ularga qarashli korxonalarning nosohaviy aktivlari" : location === "ru" ? "Нематериальные активы предприятий и их дочерних предприятий" : "Intangible assets of enterprises and their affiliated enterprises") :
          location1.pathname === "/ijro-organi" ? (location === "uz" ? "Ijro organining samaradorligi" : location === "ru" ? "Эффективность деятельности исполнительного органа" : "Effectiveness of executive learning") :
          location1.pathname === "/korparativ-boshqaruv" ? (location === "uz" ? "Korporativ boshqaruv tizimining baxolash natijalari" : location === "ru" ? "Результаты оценки системы корпоративного управления" : "Evaluation results of the corporate management system") :
          location1.pathname === "/ragbatlantirish-miqdori" ? (location === "uz" ? "Boshqaruv va nazorat organlariga to'langan rag'batlantirish miqdorlari" : location === "ru" ? "Суммы стимулирования, выплачиваемые субъектам управления и контроля" : "Amounts of incentives paid to management and control subjects") :
          location1.pathname === "/bitimlar" ? (location === "uz" ? "Affillangan shaxslar bilan tuzilgan bitimlar" : location === "ru" ? "Сделки, заключенные с аффилированными лицами" : "Agreements with affiliates") :
                                              (location === "uz" ? "Xujjatlar" : location === "ru" ? "Документы" : "Documents")
        }
        // breadCrumb={
        //   location1.pathname === "/xisobotlar" ? (location === "uz" ? "Hisobotlar" : "Отчеты") : "Xujjatlar"
        // }
      />

      <div className="container mx-auto">
        <div className="py-5">
          {location1.pathname && (
            <div className="tabs flex items-center gap-5 justify-center mb-4">
              <h1
                onClick={() => setStateYear("2023")}
                className={`py-2 px-5 border border-logo rounded cursor-pointer duration-300 ${
                  stateYear === "2023" ? "bg-logo text-white" : ""
                }`}
              >
                2023 {t("year")}
              </h1>
              <h1
                onClick={() => setStateYear("2024")}
                className={`py-2 px-5 border border-logo rounded cursor-pointer duration-300 ${
                  stateYear === "2024" ? "bg-logo text-white" : ""
                }`}
              >
                2024 {t("year")}
              </h1>
              {/*<h1*/}
              {/*  onClick={() => setStateYear('2025')}*/}
              {/*  className={`py-2 px-5 border border-logo rounded cursor-pointer duration-300 ${stateYear === '2025' ? 'bg-logo' +*/}
              {/*    ' text-white' : ''}`}*/}
              {/*>*/}
              {/*  2025 {t('yil')}*/}
              {/*</h1>*/}
            </div>
          )}
          {filteredData.length === 0 ? (
            <div className={"mx-auto w-full text-center"}>
              <img src={Image} alt="" className={"w-1/4 mx-auto"} />
              <h1 className={"text-3xl"}>{t("soon")}</h1>
            </div>
          ) : (
            location1.pathname &&
            filteredData &&
            filteredData?.map((item, index) => (
              <div key={index} onClick={() => handleToggle(index)}>
                <div
                  className={`px-2 py-4 mt-2 cursor-pointer flex items-center justify-between ${
                    openIndex === index
                      ? "bg-logo text-white"
                      : "bg-accordion text-dark"
                  } duration-200`}
                >
                  <div
                    className={""}
                    dangerouslySetInnerHTML={{
                      __html: location === "uz" ? item?.name_uz : location === "ru" ? item?.name_ru : item.name_en,
                    }}
                  />
                  {openIndex === index ? (
                    <h1 className={"text-3xl"}>-</h1>
                  ) : (
                    <h1 className={"text-3xl"}>+</h1>
                  )}
                </div>
                {openIndex === index && (
                  <div
                    className={
                      "bg-accordion_content px-4 py-2 w-[99%] m-auto flex flex-col gap-2"
                    }
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          location === "uz"
                            ? item?.content_uz
                            : location === "ru" ? item?.content_ru
                            : item?.content_en,
                      }}
                    />
                    <a
                      rel="noreferrer"
                      title="yuklash"
                      className="ml-auto px-4 py-2 mt-3 rounded-[4px] bg-[#0E0E4B] text-white xs:px-2 xs:py-1 justify-center duration-200 opacity-70 hover:opacity-100"
                      href={item.file}
                      target={"_blank"}
                    >
                      {t("download")}
                    </a>
                  </div>
                )}
              </div>
            ))
          )}
        </div>
      </div>
    </section>
  );
}
