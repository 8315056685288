import React, {useEffect, useState, useRef} from "react";
import Aos from "aos";
import {Link, useNavigate} from "react-router-dom";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/swiper-bundle.min.css";
import {Navigation, Pagination, Scrollbar, A11y, EffectFade} from "swiper";
import {useTranslation} from "react-i18next";
import about from "../../assets/kollaj1.png";
import {navbarAnimation, pageAnimation} from "../../components/Animation/Animate";
import {useDispatch, useSelector} from "react-redux";
import {
  getNews,
  getPartners,
  getProducts,
  getStatistics,
  sendContact,
} from "../../redux/actions/actions";
import {toast} from "react-toastify";
import Marquee from "react-fast-marquee";
// import moment from "moment/moment";
import InputMask from "react-input-mask";
import VanillaTilt from "vanilla-tilt";
import BgVd from "../../assets/bgVd.mp4";
import BgVd2 from "../../assets/unicon.mp4";
import ReactPlayer from "react-player/lazy";

// import RekModal from "../../components/modal/rekModal";

function Tilt(props) {
  const {options, ...rest} = props;
  const tilt = useRef(null);

  useEffect(() => {
    VanillaTilt.init(tilt.current, options);
  }, [options]);

  return <div ref={tilt} {...rest} />;
}

function Home() {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [width, setWidth] = useState(0);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [state, setState] = useState(false);
  const [state1, setState1] = useState(false);

  // const [hoveredItemId, setHoveredItemId] = useState(null);

  // const [autoplay, setAutoplay] = useState(true);

  // const handleSlideChange = () => {
  //   if (hoveredItemId !== null) {
  //     setAutoplay(false);
  //   }
  // };

  // const handleSlideChangeTransitionEnd = () => {
  //   if (hoveredItemId === null) {
  //     setAutoplay(true);
  //   }
  // };

  // const videoRef = useRef(null);

  // useEffect(() => {
  //   const streamVideo = async () => {
  //     try {
  //       const response = await fetch(
  //         `https://back.unicon.uz/api/videos/8/stream`
  //       );
  //       if (!response.ok) {
  //         throw new Error("Failed to fetch video stream");
  //       }
  //       const videoStream = response.body;
  //       const videoBlob = await videoStream.blob();
  //       const videoURL = URL.createObjectURL(videoBlob);
  //       videoRef.current.src = videoURL;
  //     } catch (error) {
  //       console.error("Error fetching or streaming video:", error);
  //     }
  //   };
  //   streamVideo();
  // }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name) {
      toast.error("Ismingiz yoki tashkilot nomini kiritmadingiz!");
    } else if (!email) {
      toast.error("Pochtangizni kiritmadingiz!");
    } else if (!phone_number) {
      toast.error("Telefon raqamingizni kiritmadingiz!");
    } else if (!message) {
      toast.error("Xabarni kiritmadingiz!");
    } else {
      dispatch(sendContact({full_name: name, email, phone_number, message}));
      toast.success("Xabaringiz yuborildi tez orada bo'glanamiz");
      setName("");
      setEmail("");
      setPhoneNumber("");
      setMessage("");
    }
  };

  const type = "yangiliklar?last_count=4";

  useEffect(() => {
    const fetchProducts = () => {
      setLoading(true);
      try {
        dispatch(getProducts());
        dispatch(getNews({type}));
        dispatch(getStatistics());
        dispatch(getPartners());
        setLoading(false);
      } catch (e) {
        toast.error(e.message);
        console.log(e);
        setLoading(false);
      }
    };
    // window.scrollTo(0, 0);
    fetchProducts();
    Aos.init();
    changeWindow();
  }, [dispatch]);

  // const { products } = useSelector((state) => state.products);
  const {news} = useSelector((state) => state.news);
  const {location} = useSelector((state) => state.location);
  const {statistics} = useSelector((state) => state.statistics);
  const {partners} = useSelector((state) => state.partners);

  const changeWindow = () => {
    window.addEventListener("resize", () => {
      if (window.innerWidth < 768) {
        return setWidth(1);
      } else if (window.innerWidth < 1024) {
        return setWidth(2);
      } else {
        return setWidth(3);
      }
    });
  };

  return (
    <>
      {/* <RekModal/> */}
      {/* <Preloader /> */}
      <div
        initial="initial"
        animate="animate"
        exit="exit"
        variants={navbarAnimation}
        className="home relative h-screen lg:h-3/4 flex items-center z-20 justify-center xs:py-40 lg:py-40"
      >
        <ReactPlayer
          className="h-screen w-full lg:h-3/4 object-cover absolute -z-10 top-0 left-0"
          width={"100%"}
          height={"100vh"}
          loop={true}
          playing={true}
          muted
          url="https://back.unicon.uz/media/bosh_sahifa/unicon_logo_2.mp4"
          // url={BgVd}
        />
      </div>

      {/* <div
        data-aos="zoom-in-left"
        className="absolute w-full bg-white xl:relative bottom-0 z-30 status py-8 xl:py-5 xl:mt-10"
        data-aos-duration={"1000"}
      >
        <div className="container mx-auto rounded-lg">
          <div className="box flex justify-between items-center p-2 rounded-lg md:flex-wrap md:gap-5">
            <div className="flex justify-between items-center gap-2 w-full">
              <h1 className="flex items-center gap-2 text-3xl md:text-xl font-bold xs:text-sm xm:text-xs text-start text-logo">
                <span>
                  <i className="ri-gift-line"></i>
                </span>
                {location === 'uz' ? <>Ro'yxatdan o'ting va ICT WEEK-2024 stendimizda sovg'alarni qo'lga kiriting</> : location === 'ru' ? "Зарегистрируйтесь и получите призы на нашем стенде ICT WEEK-2024" : "Sign up and get prizes at the our booth on ICT WEEK-2024"}
              </h1>
              <button
                onClick={() => navigate('/register')}
                className="flex items-center gap-2 text-lg xs:text-sm md:text-sm px-4 py-2 rounded bg-[#0E0E4B] text-white xs:px-2 xs:py-1 justify-center duration-200 opacity-70 hover:opacity-100"
              >
                {location === 'uz' ? "Ro'yxatdan o'tish" : location === 'ru' ? "Регистрация" : "Register"}
                <i className="ri-gift-line"></i>
              </button>
            </div>
          </div>
        </div>
      </div> */}

      <div
        data-aos="zoom-in-left"
        className="absolute w-full bg-white xl:relative bottom-0 z-30 status py-8 xl:py-5 xl:mt-10"
        // className="absolute w-full bg-white xl:relative bottom-0 z-30 status py-8 xl:py-5 xl:mt-10"
        data-aos-duration={"1000"}
      >
        <div className="container mx-auto rounded-lg">
          <div className="box flex justify-between items-center p-2 rounded-lg md:flex-wrap md:gap-5">
            {statistics &&
              statistics?.map((item) => (
                <div key={item.id} className="flex flex-col gap-2 md:w-[45%]">
                  <div className="flex flex-col gap-2 md:w-full text-center">
                    <h1 className="text-4xl font-bold xs:text-2xl text-center text-logo">
                      {item.value}
                    </h1>
                    <span className="text-2xl xs:text-xl">
                      {location === "uz"
                        ? item.name_uz
                        : location === "ru"
                          ? item.name_ru
                          : item.name_en}
                    </span>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      <section
        className="about pt-20"
        // className="about py-7"
        data-aos="fade-down-left"
        data-aos-duration={"1000"}
      >
        <div
          initial="initial"
          animate="animate"
          exit="exit"
          variants={pageAnimation}
          className="container mx-auto"
        >
          {/*<iframe src="https://66cd97cf310e99beffbb9686--teal-cactus-6d4b14.netlify.app/" className="w-full h-screen"></iframe>*/}
          <div className="box relative flex items-center justify-between flex-wrap">
            <div className="left w-1/2 sm:w-full sm:order-2">
              <h1
                className="text-4xl font-semibold uppercase text-[#0E0E4B] py-10 relative after:absolute after:bottom-7 after:left-[25%] after:-translate-x-1/2 after:w-1/2 after:bg-[#3a3c82] after:h-[3px]"
              >
                {t("about")}
              </h1>
              <p className="text-base mb-2">{t("about_text")}</p>

              <div className="flex h-16 xl:h-auto items-center rounded-[4px] bg-bd p-2 mb-3">
                <div className="bg-white rounded-sm px-2 md:px-1 mr-2">1</div>
                <div>{t("about_text_1")}</div>
              </div>
              <div className="flex h-16 xl:h-auto items-center rounded-[4px] bg-bd p-2 mb-3">
                <div className="bg-white rounded-sm px-2 md:px-1 mr-2">2</div>
                <div>{t("about_text_2")}</div>
              </div>
              <div className="flex h-16 xl:h-auto items-center rounded-[4px] bg-bd p-2 mb-3">
                <div className="bg-white rounded-sm px-2 md:px-1 mr-2">3</div>
                <div>{t("about_text_3")}</div>
              </div>
              <div className="flex h-16 xl:h-auto items-center rounded-[4px] bg-bd p-2 mb-3">
                <div className="bg-white rounded-sm px-2 md:px-1 mr-2">4</div>
                <div>{t("about_text_4")}</div>
              </div>
              <div className="flex h-16 xl:h-auto items-center rounded-[4px] bg-bd p-2 mb-3">
                <div className="bg-white rounded-sm px-2 md:px-1 mr-2">5</div>
                <div>{t("about_text_5")}</div>
              </div>
              <div className="flex h-16 xl:h-auto items-center rounded-[4px] bg-bd p-2 mb-3">
                <div className="bg-white rounded-sm px-2 md:px-1 mr-2">6</div>
                <div>{t("about_text_6")}</div>
              </div>
              <div className="flex h-16 xl:h-auto items-center rounded-[4px] bg-bd p-2 mb-3">
                <div className="bg-white rounded-sm px-2 md:px-1 mr-2">7</div>
                <div>{t("about_text_7")}</div>
              </div>

              <div className="flex">
                <Link
                  to="/tarix"
                  className="flex items-center gap-2 px-4 py-2 mt-3 rounded-[4px] bg-[#0E0E4B] text-white xs:px-2 xs:py-1 justify-center duration-200 opacity-70 hover:opacity-100"
                >
                  {t("more")}
                </Link>
              </div>
            </div>
            <div className="right w-1/2 sm:w-full xs:mt-3 xs:order-1 pt-14">
              <img src={about} alt="unicon employees"/>
            </div>
          </div>
        </div>
      </section>

      {/* <section>
        <div className="container mx-auto">
          <h2
            className='text-4xl text-[#0E0E4B] py-10'>
            Ochiq ma’lumotlar
          </h2>

          <div className='open-info-cardwrap flex flex-wrap justify-between gap-5'>
            <Link to={''} className="open-info-card w-[32%] p-10 text-center hover:bg-bd transition-colors" style={{boxShadow: "0 0 20px 0 rgb(24 50 67 / 12%)"}}>
                <img className='m-auto mb-4' src={OICheck} alt="image" />
                <h3 className='font-bold text-xl'>KORRUPSIYAGA QARSHI SIYOSAT</h3>
            </Link>

            <Link to={''} className="open-info-card w-[32%] p-10 text-center hover:bg-bd transition-colors" style={{boxShadow: "0 0 20px 0 rgb(24 50 67 / 12%)"}}>
                <img className='m-auto mb-4' src={OIFile} alt="image" />
                <h3 className='font-bold text-xl'>HUJJATLAR</h3>
            </Link>

            <Link to={''} className="open-info-card w-[32%] p-10 text-center hover:bg-bd transition-colors" style={{boxShadow: "0 0 20px 0 rgb(24 50 67 / 12%)"}}>
                <img className='m-auto mb-4' src={OIReport} alt="image" />
                <h3 className='font-bold text-xl'>HISOBOTLAR</h3>
            </Link>

            <Link to={'/org-structure'} className="open-info-card w-[32%] p-10 text-center hover:bg-bd transition-colors" style={{boxShadow: "0 0 20px 0 rgb(24 50 67 / 12%)"}}>
                <img width={65} className='m-auto mb-4' src={OIHierarchy} alt="image" />
                <h3 className='font-bold text-xl'>TASHKILIY TUZILMA</h3>
            </Link>

            <Link to={'/job-vacancy'} className="open-info-card w-[32%] p-10 text-center hover:bg-bd transition-colors" style={{boxShadow: "0 0 20px 0 rgb(24 50 67 / 12%)"}}>
                <img width={65} className='m-auto mb-4' src={OIRecruitment} alt="image" />
                <h3 className='font-bold text-xl'>BO’SH ISH O’RINLARI</h3>
            </Link>

            <Link to={''} className="open-info-card w-[32%] p-10 text-center hover:bg-bd transition-colors" style={{boxShadow: "0 0 20px 0 rgb(24 50 67 / 12%)"}}>
                <img width={65} className='m-auto mb-4' src={OIShopping} alt="image" />
                <h3 className='font-bold text-xl'>XARIDLAR</h3>
            </Link>
          </div>
        </div>
      </section> */}

      <section
        className="unicon-info mt-10"
        data-aos="zoom-in"
        data-aos-duration={"1000"}
      >
        <div
          initial="initial"
          animate="animate"
          exit="exit"
          variants={pageAnimation}
          className="container "
        >
          <Swiper
            direction={"horizontal"}
            slidesPerView={1}
            spaceBetween={30}
            modules={[Navigation, Pagination]}
            effect="fade"
            pagination={{
              clickable: true,
              el: ".swiper-pagination",
              type: "bullets",
            }}
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            // scrollbar={{draggable: true}}
            // className="mySwiper w-full"
            className="clientSwiper w-full hover:shadow-2xl"
          >
            <Tilt>
              <SwiperSlide>
                <div
                  style={{
                    boxShadow: "0 0 20px 0 rgb(24 50 67 / 12%)",
                  }}
                  className="videorolik flex flex-col gap-1 w-full relative overflow-hidden cursor-pointer"
                  onClick={() => setState(true)}
                >
                  <video
                    className="videoUnicon absolute w-full object-cover"
                    src={BgVd2}
                    loop={true}
                    autoPlay={true}
                    muted
                  />

                  <div className="videorolik__link cursor-pointer">
                    <div className="round1">
                      <div className="round2">
                        <svg
                          width="180"
                          height="180"
                          viewBox="0 0 212 212"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M66.9124 50.4207C65.0896 68.8757 64.1993 87.411 64.2447 105.956C64.2447 130.698 65.7464 150.096 66.9124 161.509C83.8032 153.841 100.298 145.33 116.335 136.007C132.422 126.782 148.035 116.756 163.116 105.965C148.037 95.1643 132.427 85.1258 116.344 75.8872C100.302 66.5784 83.8044 58.0789 66.9124 50.4207ZM50.0849 42.0467C50.3217 40.0401 51.0238 38.1166 52.1352 36.4293C53.2467 34.7421 54.7368 33.3376 56.4869 32.3279C58.2369 31.3182 60.1986 30.7311 62.2156 30.6134C64.2326 30.4956 66.2493 30.8506 68.1049 31.6498C77.4859 35.6602 98.5092 45.1913 125.186 60.5878C151.871 75.9932 170.642 89.4463 178.795 95.5502C185.756 100.771 185.774 111.123 178.804 116.362C170.731 122.43 152.189 135.706 125.186 151.306C98.1559 166.906 77.3799 176.322 68.0872 180.279C60.0842 183.698 51.1272 178.513 50.0849 169.883C48.8659 159.795 46.5869 136.89 46.5869 105.956C46.5869 75.0392 48.8571 52.1432 50.0849 42.0467Z"
                            fill="white"
                            fillOpacity="0.95"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>

                  <h2 className="videorolik__title uppercase">
                    {location === "uz"
                      ? 'OAV "UNICON.UZ" MCHJ HAQIDA '
                      : location === "ru"
                        ? 'СМИ О ООО "UNICON.UZ"'
                        : 'ABOUT "UNICON.UZ" LLC'}
                  </h2>
                </div>
              </SwiperSlide>
            </Tilt>
            <Tilt>
              <SwiperSlide>
                <div
                  style={{
                    boxShadow: "0 0 20px 0 rgb(24 50 67 / 12%)",
                  }}
                  className="videorolik flex flex-col gap-1 w-full relative overflow-hidden cursor-pointer"
                  onClick={() => setState1(true)}
                >
                  <video
                    className="videoUnicon absolute w-full object-cover"
                    src={BgVd}
                    loop={true}
                    autoPlay={true}
                    muted
                  />

                  <div className="videorolik__link cursor-pointer">
                    <div className="round1">
                      <div className="round2">
                        <svg
                          width="180"
                          height="180"
                          viewBox="0 0 212 212"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M66.9124 50.4207C65.0896 68.8757 64.1993 87.411 64.2447 105.956C64.2447 130.698 65.7464 150.096 66.9124 161.509C83.8032 153.841 100.298 145.33 116.335 136.007C132.422 126.782 148.035 116.756 163.116 105.965C148.037 95.1643 132.427 85.1258 116.344 75.8872C100.302 66.5784 83.8044 58.0789 66.9124 50.4207ZM50.0849 42.0467C50.3217 40.0401 51.0238 38.1166 52.1352 36.4293C53.2467 34.7421 54.7368 33.3376 56.4869 32.3279C58.2369 31.3182 60.1986 30.7311 62.2156 30.6134C64.2326 30.4956 66.2493 30.8506 68.1049 31.6498C77.4859 35.6602 98.5092 45.1913 125.186 60.5878C151.871 75.9932 170.642 89.4463 178.795 95.5502C185.756 100.771 185.774 111.123 178.804 116.362C170.731 122.43 152.189 135.706 125.186 151.306C98.1559 166.906 77.3799 176.322 68.0872 180.279C60.0842 183.698 51.1272 178.513 50.0849 169.883C48.8659 159.795 46.5869 136.89 46.5869 105.956C46.5869 75.0392 48.8571 52.1432 50.0849 42.0467Z"
                            fill="white"
                            fillOpacity="0.95"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>

                  <h2 className="videorolik__title uppercase">
                    {location === "uz"
                      ? '"UNICON.UZ" mchj haqida videorolik'
                      : location === "ru"
                        ? 'ВИДЕОРОЛИК О ООО "UNICON.UZ"'
                        : 'Video clip about "UNICON.UZ" LLC'}
                  </h2>
                </div>
              </SwiperSlide>
            </Tilt>
          </Swiper>
          <div className="button-Atrrangment">
            <div className="button-swiper">
              <div className="swiper-button-prev"></div>
              <div className="swiper-pagination"></div>
              <div className="swiper-button-next"></div>
            </div>
          </div>

          {state1 && (
            <div
              className="video-wrap"
              onClick={() => setState1(false)}
              style={{
                display: state1 ? "flex" : "",
              }}
            >
              <div className="video-wrap__modal">
                <iframe
                  width="100%"
                  height={500}
                  // src="https://www.youtube.com/embed/c862omzgBSk?si=ufUqzO_HIBJb_mBP"
                  src="https://www.youtube.com/embed/yfZppyhb-BE?si=NmCKTCnrFg261kab"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          )}

          {state && (
            <div
              className="video-wrap"
              onClick={() => setState(false)}
              style={{
                display: state ? "flex" : "",
              }}
            >
              <div className="video-wrap__modal">
                <iframe
                  width="100%"
                  height={500}
                  src="https://www.youtube.com/embed/jhWHFpNff2M?si=dbugnLAkvj_ddYmF"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          )}
        </div>
      </section>

      <section data-aos="zoom-in-down" data-aos-duration={"1000"}>
        <div className="container pt-14">
          <h2
            className="text-4xl font-semibold uppercase text-[#0E0E4B] py-10 relative after:absolute after:bottom-7 after:left-[12.5%] after:-translate-x-1/2 after:w-1/4 after:bg-[#3a3c82] after:h-[3px]"
          >
            {t("xizmatlar")}
          </h2>
        </div>
        <div className="container flex flex-wrap justify-between gap-5 py-5">
          <div
            className="servis-card flex flex-col items-center p-10 pb-7 w-[32%] xl:w-[48%] rounded-[4px] text-center hover:shadow-2xl"
            style={{
              border: "1px solid rgb(171, 184, 195)",
            }}
          >
            <h3 className="text-xl text-logo font-bold mb-4">
              {t("servis_title1")}
            </h3>
            <p className="mb-4">{t("servis_text1")}</p>
            <a
              rel="noreferrer"
              href={"https://xizmatlar.unicon.uz/service/23"}
              // title={t("soon_serv")}
              target="_blank"
              className="flex items-center gap-2 px-4 py-2 mt-3 rounded-[4px] bg-[#0E0E4B] text-white xs:px-2 xs:py-1 justify-center duration-200 opacity-70 hover:opacity-100"
            >
              {t("more")}
            </a>
          </div>

          <div
            className="servis-card flex flex-col items-center p-10 pb-7 w-[32%] xl:w-[48%] rounded-[4px] text-center hover:shadow-2xl"
            style={{
              border: "1px solid rgb(171, 184, 195)",
            }}
          >
            <h3 className="text-xl text-logo font-bold mb-4">
              {t("servis_title2")}
            </h3>
            <p className="mb-4">{t("servis_text2")}</p>
            <a
              rel="noreferrer"
              href={"https://xizmatlar.unicon.uz/service/18"}
              // title={t("soon_serv")}
              target="_blank"
              className="flex items-center gap-2 px-4 py-2 mt-3 rounded-[4px] bg-[#0E0E4B] text-white xs:px-2 xs:py-1 justify-center duration-200 opacity-70 hover:opacity-100"
            >
              {t("more")}
            </a>
          </div>

          <div
            style={{
              border: "1px solid rgb(171, 184, 195)",
              // boxShadow: "0 0 20px 0 rgb(24 50 67 / 12%)",
            }}
            className="servis-card flex flex-col items-center p-10 pb-7 w-[32%] xl:w-[48%] rounded-[4px] text-center hover:shadow-2xl"
          >
            <h3 className="text-xl text-logo font-bold mb-4">
              {t("servis_title3")}
            </h3>
            <p className="mb-4">{t("servis_text3")}</p>
            <a
              rel="noreferrer"
              // title={t("soon_serv")}
              href={"https://xizmatlar.unicon.uz/service/22"}
              target="_blank"
              className="flex items-center gap-2 px-4 py-2 mt-3 rounded-[4px] bg-[#0E0E4B] text-white xs:px-2 xs:py-1 justify-center duration-200 opacity-70 hover:opacity-100"
            >
              {t("more")}
            </a>
          </div>
        </div>
      </section>

      <section
        data-aos="zoom-in-down"
        className="bg-logo text-white py-7 my-10"
        data-aos-duration={"1000"}
      >
        <div className="container mx-auto flex justify-between items-center flex-wrap gap-3">
          <div>
            <h2 className="uppercase font-bold text-3xl xs:text-2xl mb-2">
              {t("portfolio")}
            </h2>
            <p className="uppercase xs:text-base">{t("xizmatlar1")}</p>
          </div>

          <div className="mt-3">
            <a
              // title={t("soon_serv")}
              rel="noreferrer"
              target="_blank"
              className="px-4 py-2 xs:px-2 xs:py-1 rounded-[4px] bg-logo hover:bg-white border border-solid text-white hover:text-logo font-semibold"
              href="https://xizmatlar.unicon.uz/"
            >
              {t("see")}
            </a>
          </div>
        </div>
      </section>

      <section
        data-aos="zoom-in-left"
        className="news"
        data-aos-duration={"1000"}
      >
        <div className="container">
          <h2
            className="text-4xl font-semibold uppercase text-[#0E0E4B] py-10 relative after:absolute after:bottom-7 after:left-[12.5%] after:-translate-x-1/2 after:w-1/4 after:bg-[#3a3c82] after:h-[3px]"
          >
            {t("news")}
          </h2>

          {news?.success && (
            <div className="flex justify-between">
              <Link
                to={`/news/${news?.result[0]?.id}`}
                className="w-[45%] lg:hidden"
              >
                {news?.result[0]?.files[0]?.is_video ? (
                  <iframe
                    className="home_news rounded-[4px] mb-5"
                    title="video"
                    src={`${news?.result[0]?.files[0]?.video_url}`}
                    width={"100%"}
                    height={"100%"}
                    style={{
                      height: "230px",
                      width: "100%",
                      objectFit: "cover",
                    }}
                    frameBorder={0}
                    allowFullScreen={true}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  />
                ) : (
                  <img
                    className="home_news rounded-[4px] mb-5"
                    src={`${news?.result[0]?.files[0]?.file}`}
                    alt="rasm"
                  />
                )}

                <h3 className="text-xl font-semibold mb-3">{`${
                  location === "uz"
                    ? news?.result[0]?.title_uz?.slice(0, 80)
                    : location === "ru"
                      ? news?.result[0]?.title_ru?.slice(0, 80)
                      : news?.result[0]?.title_en?.slice(0, 80)
                }...`}</h3>
                <div
                  className="mb-3"
                  dangerouslySetInnerHTML={{
                    __html:
                      location === "uz"
                        ? `${news?.result[0]?.content_uz?.substring(3, 110)}...`
                        : location === "ru"
                          ? `${news?.result[0]?.content_ru?.substring(3, 110)}...`
                          : `${news?.result[0]?.content_en?.substring(
                            3,
                            110
                          )}...`,
                  }}
                />
              </Link>

              <div className="flex flex-col gap-8 w-1/2 lg:w-full">
                {news?.result[1] && (
                  <Link
                    to={`/news/${news?.result[1]?.id}`}
                    className="flex justify-between sm:flex-col"
                  >
                    <div className="relative w-[30%] sm:w-full mb-2">
                      {news?.result[1]?.files[0]?.is_video ? (
                        <iframe
                          className="w-full h-36 object-cover rounded-[4px]"
                          title="video"
                          src={`${news?.result[1]?.files[0]?.video_url}`}
                          width={"100%"}
                          height={"100%"}
                          style={{
                            height: "230px",
                            width: "100%",
                            objectFit: "cover",
                          }}
                          frameBorder={0}
                          allowFullScreen={true}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        />
                      ) : (
                        <img
                          className="w-full h-36 object-cover rounded-[4px]"
                          src={`${news?.result[1]?.files[0]?.file}`}
                          alt="rasm"
                        />
                      )}
                    </div>
                    <div className="w-[65%] sm:w-full">
                      <h3 className="text-xl font-semibold mb-2">{`${
                        location === "uz"
                          ? news?.result[1]?.title_uz?.slice(0, 80)
                          : location === "ru"
                            ? news?.result[1]?.title_ru?.slice(0, 80)
                            : news?.result[1]?.title_en?.slice(0, 80)
                      }...`}</h3>
                      <div
                        className="mb-7"
                        dangerouslySetInnerHTML={{
                          __html:
                            location === "uz"
                              ? `${news?.result[1]?.content_uz?.substring(
                                3,
                                110
                              )}...`
                              : location === "ru"
                                ? `${news?.result[1]?.content_ru?.substring(
                                  3,
                                  110
                                )}...`
                                : `${news?.result[1]?.content_en?.substring(
                                  3,
                                  110
                                )}...`,
                        }}
                      />
                    </div>
                  </Link>
                )}

                {news?.result[2] && (
                  <Link
                    to={`/news/${news?.result[2]?.id}`}
                    className="flex justify-between sm:flex-col"
                  >
                    <div className="relative w-[30%] sm:w-full mb-2">
                      {news?.result[2]?.files[0]?.is_video ? (
                        <iframe
                          className="w-full h-36 object-cover rounded-[4px]"
                          title="video"
                          src={`${news?.result[2]?.files[0]?.video_url}`}
                          width={"100%"}
                          height={"100%"}
                          style={{
                            height: "230px",
                            width: "100%",
                            objectFit: "cover",
                          }}
                          frameBorder={0}
                          allowFullScreen={true}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        />
                      ) : (
                        <img
                          className="w-full h-36 object-cover rounded-[4px]"
                          src={`${news?.result[2]?.files[0]?.file}`}
                          alt="rasm"
                        />
                      )}
                      {/* <span className="absolute top-3 left-3 inline-block p-1 px-3 bg-hover rounded-3xl text-white font-medium mb-7">
                      {moment(news?.result[2]?.created_at).format(
                        "DD-MM-YYYY HH:mm"
                      )}
                    </span> */}
                    </div>
                    <div className="w-[65%] sm:w-full">
                      <h3 className="text-xl font-semibold mb-2">{`${
                        location === "uz"
                          ? news?.result[2]?.title_uz?.slice(0, 80)
                          : location === "uz"
                            ? news?.result[2]?.title_ru?.slice(0, 80)
                            : news?.result[2]?.title_en?.slice(0, 80)
                      }...`}</h3>
                      <div
                        className="mb-7"
                        dangerouslySetInnerHTML={{
                          __html:
                            location === "uz"
                              ? `${news?.result[2]?.content_uz?.substring(
                                3,
                                110
                              )}...`
                              : location === "ru"
                                ? `${news?.result[2]?.content_ru?.substring(
                                  3,
                                  110
                                )}...`
                                : `${news?.result[2]?.content_en?.substring(
                                  3,
                                  110
                                )}...`,
                        }}
                      />
                    </div>
                  </Link>
                )}

                {news?.result[3] && (
                  <Link
                    to={`/news/${news?.result[3]?.id}`}
                    className="flex justify-between sm:flex-col"
                  >
                    <div className="relative w-[30%] sm:w-full mb-2">
                      {news?.result[3]?.files[0]?.is_video ? (
                        <iframe
                          className="w-full h-36 object-cover rounded-[4px]"
                          title="video"
                          src={`${news?.result[3]?.files[0]?.video_url}`}
                          width={"100%"}
                          height={"100%"}
                          style={{
                            height: "230px",
                            width: "100%",
                            objectFit: "cover",
                          }}
                          frameBorder={0}
                          allowFullScreen={true}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        />
                      ) : (
                        <img
                          className="w-full h-36 object-cover rounded-[4px]"
                          src={`${news?.result[3]?.files[0]?.file}`}
                          alt="rasm"
                        />
                      )}
                      {/* <span className="absolute top-3 left-3 inline-block p-1 px-3 bg-hover rounded-3xl text-white font-medium mb-7">
                      {moment(news?.result[3]?.created_at).format(
                        "DD-MM-YYYY HH:mm"
                      )}
                    </span> */}
                    </div>
                    <div className="w-[65%] sm:w-full">
                      <h3 className="text-xl font-semibold mb-2">{`${
                        location === "uz"
                          ? news?.result[3]?.title_uz?.slice(0, 80)
                          : location === "ru"
                            ? news?.result[3]?.title_ru?.slice(0, 80)
                            : news?.result[3]?.title_en?.slice(0, 80)
                      }...`}</h3>
                      <div
                        className="mb-7"
                        dangerouslySetInnerHTML={{
                          __html:
                            location === "uz"
                              ? `${news?.result[3]?.content_uz?.substring(
                                3,
                                110
                              )}...`
                              : location === "ru"
                                ? `${news?.result[3]?.content_ru?.substring(
                                  3,
                                  110
                                )}...`
                                : `${news?.result[3]?.content_en?.substring(
                                  3,
                                  110
                                )}...`,
                        }}
                      />
                    </div>
                  </Link>
                )}
              </div>
            </div>
          )}
        </div>
      </section>

      <section
        className="map py-10"
        data-aos="zoom-in-right"
        // data-aos={"fade-up"}
        data-aos-duration={"1000"}
      >
        <div className="container mx-auto">
          <div className="box">
            <div className="title w-2/4 xs:w-full">
              {/* <h1 className="text-logo font-bold text-3xl mb-3">{t("call")}</h1> */}
              <h2
                className="text-4xl font-semibold uppercase text-[#0E0E4B] py-10 relative after:absolute after:bottom-7 after:left-[25.2%] after:-translate-x-1/2 after:w-1/2 after:bg-[#3a3c82] after:h-[3px]"
              >
                {t("call")}
              </h2>
              <p className="text-grey">{t("call-text")}</p>
            </div>
            <div className="content flex justify-between mt-5 md:flex-wrap">
              <div
                className="left w-[48%] md:w-full rounded-[4px]"
                style={{boxShadow: "0 0 20px 0 rgb(24 50 67 / 12%)"}}
              >
                <form
                  onSubmit={handleSubmit}
                  className="w-full md:w-full flex flex-wrap justify-between gap-5 py-5 px-8"
                >
                  <label
                    htmlFor="name"
                    className="flex flex-col gap-2 w-[48%] lg:w-full md:w-full xs:w-full"
                  >
                    <span className="text-grey">{t("name")}</span>
                    <input
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                      id="name"
                      className="border border-logo rounded-lg py-2 px-4"
                    />
                  </label>
                  <label
                    htmlFor="phone"
                    className="flex flex-col gap-2 w-[48%] lg:w-full md:w-full xs:w-full"
                  >
                    <span className="text-grey">{t("phone")}</span>
                    <InputMask
                      mask={"+999 (99) 999 99 99"}
                      value={phone_number}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      className="border border-logo rounded-lg py-2 px-4"
                      type="tel"
                      name="phone_number"
                    />
                  </label>
                  <label htmlFor="email" className="flex flex-col gap-2 w-full">
                    <span className="text-grey">{t("email")}</span>
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="text"
                      id="email"
                      className="border border-logo rounded-lg py-2 px-4"
                    />
                  </label>
                  <label
                    htmlFor="textarea"
                    className="flex flex-col gap-2 w-full"
                  >
                    <span className="text-grey">{t("reference-text")}</span>
                    <textarea
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      id="textarea"
                      className="border border-logo rounded-lg py-2 px-4"
                    />
                  </label>
                  <button
                    className="ml-auto px-4 py-2 mt-3 rounded-[4px] bg-[#0E0E4B] text-white xs:px-2 xs:py-1 justify-center duration-200 opacity-70 hover:opacity-100"
                    // className="bg-logo text-white px-4 py-2 hover:bg-hover duration-300 ml-auto"
                  >
                    {t("send")}
                  </button>
                </form>
              </div>
              <div className="flex flex-col justify-between right w-1/2 md:w-full">
                <div
                  className="flex items-center gap-5 overflow-hidden md:mt-5 rounded-[4px]"
                  style={{boxShadow: "0 0 20px 0 rgb(24 50 67 / 12%)"}}
                >
                  <span className="bg-logo text-white py-6 px-6 text-5xl xs:text-2xl">
                    <i className="ri-phone-fill"></i>
                  </span>
                  <div>
                    <h1>{t("phone")}</h1>
                    <a href="tel:+998712088151">+998 71 208 81 51</a>
                  </div>
                </div>
                <div
                  className="flex items-center gap-5 mt-5 overflow-hidden rounded-[4px]"
                  style={{boxShadow: "0 0 20px 0 rgb(24 50 67 / 12%)"}}
                >
                  <span className="bg-logo text-white py-6 px-6 text-5xl xs:text-2xl">
                    <i className="ri-map-pin-fill"></i>
                  </span>
                  <div>
                    <h1>{t("address")}</h1>
                    <p>{t("address_footer")}</p>
                  </div>
                </div>
                <div
                  className="flex items-center gap-5 mt-5 overflow-hidden rounded-[4px]"
                  style={{boxShadow: "0 0 20px 0 rgb(24 50 67 / 12%)"}}
                >
                  <span className="bg-logo text-white py-6 px-6 text-5xl xs:text-2xl">
                    <i className="ri-mail-line"></i>
                  </span>
                  <div>
                    <h1>{t("email")}</h1>
                    <a href="mailto:contract@unicon.uz">
                      <span>contract@unicon.uz</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="partners py-20"
        data-aos="zoom-out-up"
        data-aos-duration={"1000"}
      >
        <div className="w-full">
          <div className="box">
            <div className="container mb-5">
              <h2
                className="text-4xl font-semibold uppercase text-[#0E0E4B] py-10 relative after:absolute after:bottom-7 after:left-[12.5%] after:-translate-x-1/2 after:w-1/4 after:bg-[#3a3c82] after:h-[3px]"
              >
                {t("partners")}
              </h2>
            </div>
            <Marquee pauseOnHover={true}>
              <div className="flex items-center gap-20 md:gap-8 xs:gap-5">
                {partners &&
                  partners?.map((item) => (
                    <img
                      key={item.id}
                      className="w-full h-[100px] object-contain md:ml-9 img"
                      src={item.icon}
                      alt=""
                    />
                  ))}
              </div>
            </Marquee>
            <Marquee
              pauseOnHover={true}
              direction={"right"}
              className="mt-10 md:gap-8 xs:gap-5"
            >
              <div className="flex items-center gap-20 md:gap-8 xs:gap-5">
                {partners &&
                  partners
                    ?.reverse()
                    .map((item) => (
                      <img
                        key={item.id}
                        className="w-full h-[100px] object-contain md:ml-9 img_reverse"
                        src={item.icon}
                        alt=""
                      />
                    ))}
              </div>
            </Marquee>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
