import React, { useState } from "react";
import logo from "../../assets/logo2.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { subscribe } from "../../redux/actions/actions";

function Footer() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // const [name, setName] = useState("Foydalanuvchi");
  const [email, setEmail] = useState("");

  return (
    <footer className="py-7 bg-logo relative">
      <div className="container mx-auto">
        <div className="box flex justify-between md:flex-wrap md:items-center">
          <div
            className="w-1/4 md:w-1/2 box-child"
            style={{ color: "rgba(255,255,255,0.8)" }}
          >
            <img
              src={logo}
              className="w-[190px] h-auto mb-8 md:mb-7 sm:w-[150px]"
              alt=""
            />
            <div className="uppercase flex gap-4 mb-2 md:justify-start md:gap-4">
              <div>
                <i className="ri-map-pin-2-fill"></i>
              </div>
              <h1>{t("address_footer")}</h1>
            </div>
            <div className="uppercase flex gap-4 mb-2">
              <div>
                <i className="ri-phone-fill"></i>
              </div>
              <a href="tel:+998712118888">+998 71-211-88-88</a>
            </div>
            <div className="uppercase flex gap-4">
              <div>
                <i className="ri-time-line"></i>
              </div>
              <h1>{t("work_day")}</h1>
            </div>
          </div>

          <div
            className="w-1/4 md:w-1/2 card_footer"
            style={{ color: "rgba(255,255,255,0.8)" }}
          >
            <h1 className="mb-8 text-xl text-white md:mb-5 md:mt-5">
              {t("call")}
            </h1>
            <h1 className="mb-2">{t("clients")}</h1>
            <div className="uppercase flex gap-2 hover:text-white duration-200 mb-2">
              <div>
                <i className="ri-phone-fill"></i>
              </div>
              <a href="tel:+998712118888">+998 71-211-88-88</a>
            </div>
            <div className="flex gap-2 hover:text-white duration-200 mb-2">
              <div>
                <i className="ri-mail-line"></i>
              </div>
              <a href="mailto:info@unicon.uz">
                <span>info@unicon.uz</span>
              </a>
            </div>
          </div>

          <div
            className="w-1/4 md:w-1/2 xs:mt-4 card_footer"
            style={{ color: "rgba(255,255,255,0.8)" }}
          >
            <h2 className="text-xl text-white mb-8 md:mb-5 md:mt-5">
              {t("news_follow")}
            </h2>
            <form className={"flex flex-wrap gap-5 md:gap-3"}>
              {/* <div className="hidden relative w-full flex-grow sm:w-full duration-300">
                <input
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="text-xl border-logo p-2 border md:p-1 w-full"
                  style={{ boxShadow: "0 0 20px 0 rgb(24 50 67 / 12%)" }}
                  type="text"
                  placeholder={"Ismingizni kiriting"}
                  name="name"
                />
              </div> */}

              <div className="relative w-full flex-grow sm:w-full duration-300">
                <input
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="text-xl border-logo p-2 border md:px-2 md:py-1 w-full text-logo"
                  style={{ boxShadow: "0 0 20px 0 rgb(24 50 67 / 12%)" }}
                  type="email"
                  placeholder={t("email")}
                  name="email"
                />
              </div>

              <div className="flex justify-end items-center">
                <button
                  onClick={(event) => {
                    event.preventDefault();
                    // if (!name) {
                    //   toast.error(
                    //     "Ismingiz yoki tashkilot nomini kiritmadingiz!"
                    //   );} else 
                    if (!email) {
                      toast.error("Pochtangizni kiritmadingiz!");
                    } else {
                      const formData = new FormData();
                      // formData.append("name", name);
                      formData.append("email", email);
                      dispatch(subscribe(formData));
                      toast.success(
                        "Xabaringiz yuborildi tez orada bo'glanamiz"
                      );
                      setEmail("");
                    }
                  }}
                  type={"submit"}
                  className="px-4 py-2 xs:px-2 xs:py-1 rounded-[4px] bg-logo hover:bg-white border border-solid text-white hover:text-logo font-semibold"
                >
                  {t("follow")}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div
          className="border border-white mt-3"
          style={{ borderColor: "rgba(255,255,255,0.3)" }}
        ></div>
        <div className="copyright mt-3 text-white flex justify-between items-center xs:flex-wrap xs:justify-center">
          <div className="flex gap-2 justify-between w-full xs:justify-center xs:flex-wrap xs:flex-col">
            <div className="flex items-center gap-2 xs:flex-col">
              <h1 className="uppercase">
                {new Date().getFullYear()} unicon.uz
              </h1>
              <h1 className="xs:pb-2">{t("copyright")}</h1>
            </div>
            <div className="flex items-center gap-4 xs:flex-col">
              <a
                href="https://metrika.yandex.ru/stat/?id=92471966&from=informer"
                target="_blank"
                rel="nofollow"
              >
                <img
                  src="https://informer.yandex.ru/informer/92471966/3_1_FFFFFFFF_EFEFEFFF_0_pageviews"
                  style={{ width: "88px", height: "31px", border: "0" }}
                  alt="Яндекс.Метрика"
                  title="Яндекс.Метрика: данные за сегодня (просмотры, визиты и уникальные посетители)"
                  className="ym-advanced-informer"
                  data-cid="92471966"
                  data-lang="ru"
                />
              </a>
              <div className="flex gap-4">
                <a
                  target="_blank"
                  className="p-2 text-2xl xs:text-base px-3 bg-[#3a3c82] duration-200 hover:bg-[#00ce87] rounded-full flex"
                  href="https://t.me/uniconcompany"
                >
                  <i className="ri-telegram-fill"></i>
                </a>
                <a
                  target="_blank"
                  className="p-2 text-2xl xs:text-base px-3 bg-[#3a3c82] duration-200 hover:bg-[#00ce87] rounded-full flex"
                  href="https://www.facebook.com/uniconuz"
                >
                  <i className="ri-facebook-line"></i>
                </a>
                <a
                  target="_blank"
                  className="p-2 text-2xl xs:text-base px-3 bg-[#3a3c82] duration-200 hover:bg-[#00ce87] rounded-full flex"
                  href="https://www.instagram.com/lic_unicon.uz/"
                >
                  <i className="ri-instagram-line"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
