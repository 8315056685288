import {
	GET_MEMBERS,
	GET_NAVBAR,
	GET_PRODUCTS,
	GET_SINGLE_PRODUCTS,
	GET_RESOURCE,
	GET_SINGLE_RESOURCE,
	GET_SINGLE_SERVICES,
	GET_SINGLE_NEWS,
	GET_NEWS,
	SEND_MESSAGE,
	SWITCH_LANG,
	SEND_CONTACT,
	GET_STATISTICS,
	GET_PARTNERS,
	GET_VACANCY,
	APPLY_VACANCY,
	GET_CORRUPTION,
	COMMUN_CHANEL,
	SUBSCRIBE,
	GET_DOCS,
	GET_PURCHES,
	GET_HERO_VIDEO,
	GET_APPEAL,
	SEND_MISTAKE_INFO,
} from "../constants/constants";
import axios from "axios";
import i18next from "i18next";

// export const baseUrl = "http://194.107.115.25/api";
export const baseUrl = "https://back.unicon.uz/api";

export const getServices = (type) => async (dispatch) => {
	try {
		const navbar = await axios.get(`${baseUrl}/moduls?modul_type=${type}`);
		dispatch({
			type: GET_NAVBAR,
			payload: navbar.data,
		});
	} catch (e) {
		console.log(e);
	}
};

export const getSingleServices = (id) => async (dispatch) => {
	try {
		const response = await axios.get(`${baseUrl}/moduls/${id}`);
		dispatch({
			type: GET_SINGLE_SERVICES,
			payload: response.data,
		});
	} catch (e) {
		console.log(e);
	}
};

export const getProducts = () => async (dispatch) => {
	try {
		const response = await axios.get(`${baseUrl}/services`);
		dispatch({
			type: GET_PRODUCTS,
			payload: response.data,
		});
	} catch (e) {
		console.log(e);
	}
};

export const getSingleProducts = (id) => async (dispatch) => {
	try {
		const response = await axios.get(`${baseUrl}/services/detail/${id}`);
		dispatch({
			type: GET_SINGLE_PRODUCTS,
			payload: response.data,
		});
	} catch (e) {
		console.log(e);
	}
};

export const getMembers = (type) => async (dispatch) => {
	try {
		const response = await axios.get(`${baseUrl}/member/${type}`);
		dispatch({
			type: GET_MEMBERS,
			payload: response.data,
		});
	} catch (e) {
		console.log(e);
	}
};

export const getStatistics = () => async (dispatch) => {
	try {
		const response = await axios.get(`${baseUrl}/statistics`);
		dispatch({
			type: GET_STATISTICS,
			payload: response.data,
		});
	} catch (e) {
		console.log(e);
	}
};

export const getPartners = () => async (dispatch) => {
	try {
		const response = await axios.get(`${baseUrl}/partners`);
		dispatch({
			type: GET_PARTNERS,
			payload: response.data,
		});
	} catch (e) {
		console.log(e);
	}
};

export const getResource = () => async (dispatch) => {
	try {
		const response = await axios.get(`${baseUrl}/product/resurslar`);
		dispatch({
			type: GET_RESOURCE,
			payload: response.data,
		});
	} catch (e) {
		console.log(e);
	}
};

export const getSingleResource = (id) => async (dispatch) => {
	try {
		const response = await axios.get(`${baseUrl}/resource/detail/${id}`);
		dispatch({
			type: GET_SINGLE_RESOURCE,
			payload: response.data,
		});
	} catch (e) {
		console.log(e);
	}
};

export const getNews = (params) => async (dispatch) => {
	try {
		if (params?.page) {
			const response = await axios.get(
				`${baseUrl}/cat/${params?.type}?page_size=${params?.page}`
			);
			dispatch({
				type: GET_NEWS,
				payload: response.data,
			});
		} else {
			const response = await axios.get(`${baseUrl}/cat/${params?.type}`);
			dispatch({
				type: GET_NEWS,
				payload: response.data,
			});
		}
	} catch (e) {
		console.log(e);
	}
};

export const getSingleNews = (id) => async (dispatch) => {
	try {
		const response = await axios.get(`${baseUrl}/cat/detail/${id}`);
		dispatch({
			type: GET_SINGLE_NEWS,
			payload: response.data,
		});
	} catch (e) {
		console.log(e);
	}
};

export const sendMessage = (items) => async (dispatch) => {
	try {
		const response = await axios.post(`${baseUrl}/create-message`, items);
		dispatch({
			type: SEND_MESSAGE,
			payload: response.data,
		});
	} catch (e) {
		console.log(e);
	}
};

export const sendContact = (items) => async (dispatch) => {
	try {
		const response = await axios.post(`${baseUrl}/contact`, items);
		dispatch({
			type: SEND_CONTACT,
			payload: response.data,
		});
	} catch (e) {
		console.log(e);
	}
};

export const switchLang = (lng) => async (dispatch) => {
	await i18next.changeLanguage(lng);
	dispatch({
		type: SWITCH_LANG,
		payload: lng,
	});
};

export const getVacancy = () => async (dispatch) => {
	try {
		const response = await axios.get(`${baseUrl}/vacancies`);
		dispatch({
			type: GET_VACANCY,
			payload: response.data,
		});
	} catch (e) {
		console.log(e);
	}
};

export const applyVacancy = (items) => async (dispatch) => {
	try {
		const headers = {
			"Content-Type": "multipart/form-data",
		};
		const response = await axios.post(`${baseUrl}/apply/vacancies`, items, {
			headers: headers,
		});
		dispatch({
			type: APPLY_VACANCY,
			payload: response.data,
		});
	} catch (e) {
		console.log(e);
	}
};

export const getCorruption = (id) => async (dispatch) => {
	try {
		const response = await axios.get(
			`${baseUrl}/corruption?corruption_type=${id}`
		);
		dispatch({
			type: GET_CORRUPTION,
			payload: response.data,
		});
	} catch (e) {
		console.log(e);
	}
};

export const getAppealDir = () => async (dispatch) => {
	try {
		const response = await axios.get(`${baseUrl}/director-message`);
		dispatch({
			type: GET_APPEAL,
			payload: response.data,
		});
	} catch (e) {
		console.log(e);
	}
};

export const communChanel = (items) => async (dispatch) => {
	try {
		const headers = {
			"Content-Type": "multipart/form-data",
		};
		const response = await axios.post(`${baseUrl}/feedback`, items, {
			headers: headers,
		});
		dispatch({
			type: COMMUN_CHANEL,
			payload: response.data,
		});
	} catch (e) {
		console.log(e);
	}
};

export const subscribe = (items) => async (dispatch) => {
	try {
		const headers = {
			"Content-Type": "multipart/form-data",
		};
		const response = await axios.post(`${baseUrl}/subscribe`, items, {
			headers: headers,
		});
		dispatch({
			type: SUBSCRIBE,
			payload: response.data,
		});
	} catch (e) {
		console.log(e);
	}
};

export const getDocs = (id) => async (dispatch) => {
	try {
		const response = await axios.get(
			`${baseUrl}/documents?document_type=${id}`
		);
		dispatch({
			type: GET_DOCS,
			payload: response.data,
		});
	} catch (e) {
		console.log(e);
	}
};

export const getPurches = (id) => async (dispatch) => {
	try {
		const response = await axios.get(`${baseUrl}/xaridlar?xarid_type=${id}`);
		dispatch({
			type: GET_PURCHES,
			payload: response.data,
		});
	} catch (e) {
		console.log(e);
	}
};

export const getHeroVideo = () => async (dispatch) => {
	try {
		const response = await axios.get(`${baseUrl}/main-video`);
		dispatch({
			type: GET_HERO_VIDEO,
			payload: response.data,
		});
	} catch (e) {
		console.log(e);
	}
};

export const sendMistakeInfo = (items) => async (dispatch) => {
	try {
		const response = await axios.post(`${baseUrl}/mistake-info`, items);
		dispatch({
			type: SEND_MISTAKE_INFO,
			payload: response.data,
		});
	} catch (e) {
		console.log(e);
	}
};
